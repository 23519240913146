<template>
  <v-card id="section-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <!-- create button -->
        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'content-sections-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Section</span>
        </v-btn>
      </div>

    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="itemsListTable"
      :options.sync="options"
      :server-items-length="totalItemListTable"
      :loading="loading"
      :items-per-page="100"
      hide-default-footer
      class="text-no-wrap"
    >


      <!-- name -->
      <template #[`item.name`]="{item}">
        <v-edit-dialog
          :return-value.sync="item.name"
          large
          @save="updateOneProperty(item.id, 'name', item.name)"
        >
          <span class="whitespace-no-wrap inline-editable">{{ short(item.name) }}</span>
          <template v-slot:input>
            <div class="mt-4 title w-500px">
              Update Name
            </div>
            <v-text-field
              v-model="item.name"
              :rules="[max60chars]"
              label="Edit"
              full-width
              single-line
              counter
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <!-- slug -->
      <template #[`item.slug`]="{item}">
        <v-edit-dialog
          :return-value.sync="item.slug"
          large
          @save="updateOneProperty(item.id, 'slug', item.slug)"
        >
          <span class="whitespace-no-wrap inline-editable">{{ short(item.slug) }}</span>
          <template v-slot:input>
            <div class="mt-4 title w-500px">
              Update Slug
            </div>
            <v-text-field
              v-model="item.slug"
              :rules="[max60chars]"
              label="Edit"
              full-width
              single-line
              counter
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'content-sections-edit', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <!-- delete -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteConfirmState.id = item.id; deleteConfirmState.visible = true"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>

        </div>
      </template>

    </v-data-table>
    <delete-confirm-dialog
      :visible="deleteConfirmState.visible"
      :id="deleteConfirmState.id"
      @close="deleteConfirmState.visible = false; deleteConfirmState.id = null"
      @confirm="deleteItem"
    />
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiTrendingUp,
} from '@mdi/js'

import {onUnmounted} from '@vue/composition-api'
import {defaultDate, defaultTime} from '@/plugins/datetime'
import {short} from '@/plugins/string'
import store from '@/store'
import itemsStore from './store'
import useSections from './composition'
import DeleteConfirmDialog from '@/components/Dialogs/DeleteConfirm.vue'

export default {
  components: {
    DeleteConfirmDialog,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-content-sections'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, itemsStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      itemsListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalItemListTable,
      loading,
      selectedTableData,
      deleteConfirmState,
      deleteItem,
      updateItem,
      updateOneProperty,
      toggleItemPublishState,
    } = useSections()

    return {
      max60chars: v => v.length <= 60 || 'Input too long!',
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalItemListTable,
      itemsListTable,
      loading,
      selectedTableData,
      deleteConfirmState,
      defaultDate,
      defaultTime,
      short,
      deleteItem,
      updateItem,
      updateOneProperty,
      toggleItemPublishState,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }

  .invoice-list-search {
    max-width: 10.625rem;
  }

  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
